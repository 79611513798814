import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { Image } from '../UI'
import styles from './HealingHerbs.module.css'
function HealingHerbs({ className }) {
  const containerClass = cn(styles.healingHerbs, className)

  const { healingTag } = useStaticQuery(
    graphql`
      query healingContent {
        healingTag: file(relativePath: { eq: "internals/healingFlorais.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  return (
    <section className={containerClass}>
      <div className={styles.content}>
        <Image className={styles.healingTag} fluid={healingTag.childImageSharp.fluid} />
        <div className={styles.footer}>Lu Moura é parceiro e representante certificado pela Healingherbes Florais.</div>
      </div>
    </section>
  )
}

HealingHerbs.propTypes = {
  className: PropTypes.string
}

export { HealingHerbs }
