import React from 'react'
import PropTypes from 'prop-types'
import { Carousel } from '../UI'
import cn from 'classnames'
import styles from './InternalTestimonial.module.css'
function InternalTestimonial({ className }) {
  const containerClass = cn(styles.internalTestimonial, className)

  return (
    <section className={containerClass}>
      <div className={styles.carouselWrapper}>
        <Carousel isWhite />
      </div>
    </section>
  )
}

InternalTestimonial.propTypes = {
  className: PropTypes.string
}

export { InternalTestimonial }
