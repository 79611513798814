import React from 'react'
import PropTypes from 'prop-types'
import styles from './InternalWorkshopTab.module.css'
function InternalWorkshopTab({ children }) {
  return <div className={styles.internalWorkshopTab}>{children}</div>
}

InternalWorkshopTab.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
}

export { InternalWorkshopTab }
