import React from 'react'
import TopCard from '../../images/home/top_card.svg'
import OwnerSignature from '../../images/internals/ownerSignature.svg'
import { OwnerSlideshow } from '../UI'
import { WHATSAPPLINK } from '../../constants/routes'
import styles from './InternalAttendance.module.css'
function InternalAttendance() {
  return (
    <div className={styles.internalAttendance}>
      <TopCard className={styles.topCard} />
      <OwnerSlideshow className={styles.ownerContentSlide} />
      <small>Online ou Presencial</small>
      <h3>
        Atendimento
        <br />
        <strong>Terapêutico</strong>
      </h3>
      <OwnerSignature className={styles.signature} />
      <a
        href={WHATSAPPLINK}
        title='Agendar Atendimento'
        target='_blank'
        rel='noopener noreferrer'
        className={`button button--purpleReverse ${styles.button}`}
      >
        <span>Agendar Atendimento</span> <small className={styles.newWindow}></small>
      </a>
    </div>
  )
}

export { InternalAttendance }
