import React, { useState } from 'react'
import PropTypes from 'prop-types'
import TopTab from '../../images/internals/top_tab.svg'
import TopTabShadow from '../../images/internals/top_shadow.svg'
import cn from 'classnames'
import styles from './InternalWorkshop.module.css'
function InternalWorkshop({ children }) {
  const [activeTab, setActiveTab] = useState(0)
  const tabClassNameOnline = cn(styles.internalTabOnline, styles.internalTab, {
    [styles.internalTabActive]: activeTab === 0
  })

  const tabClassNamePresential = cn(styles.internalTabPresential, styles.internalTab, {
    [styles.internalTabActive]: activeTab === 1
  })

  const renderTab = () => {
    if (activeTab === 0) {
      return children[0]
    }
    return children[1]
  }

  return (
    <div className={styles.internalWorkshop}>
      <div className={styles.internalTabs}>
        <nav className={styles.internalNav}>
          <button
            onClick={e => {
              e.preventDefault()
              setActiveTab(0)
            }}
            className={tabClassNameOnline}
          >
            <TopTab className={styles.topTab} />
            Online
          </button>
          <button
            onClick={e => {
              e.preventDefault()
              setActiveTab(1)
            }}
            className={tabClassNamePresential}
          >
            <TopTab className={styles.topTab} />
            <TopTabShadow className={styles.topTabShadow} />
            Presencial
          </button>
        </nav>
        {renderTab()}
      </div>
    </div>
  )
}

InternalWorkshop.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
}

export { InternalWorkshop }
