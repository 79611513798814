import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { JORNADA } from '../../constants/routes'
import { Image } from '../UI'
import styles from './InternalInstructor.module.css'
function InternalInstructor() {
  const { owner } = useStaticQuery(
    graphql`
      query instructorContent {
        owner: file(relativePath: { eq: "internals/owner.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )
  return (
    <section className={styles.InternalInstructor}>
      <Image className={styles.owner} fluid={owner.childImageSharp.fluid} />
      <div>
        <h1>
          <small>Facilitadora</small>
          Luciana Moura
        </h1>
        <p>
          Terapeuta Floral Sistêmica
          <br />
          CRTH - BR 2285
          <br />
          Register of Practitioners BZP20121210R
        </p>
      </div>
      <Link to={JORNADA} className={styles.link} title='Luciana Moura - Terapeuta Floral Sistêmica' />
    </section>
  )
}

export { InternalInstructor }
