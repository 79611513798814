import React from 'react'
import styles from './InternalHowDoYouLearn.module.css'
function InternalHowDoYouLearn() {
  return (
    <div className={styles.internalHowDoYouLearn}>
      <h2>
        Como prefere <strong>aprender</strong>
      </h2>
      <h5>Ensino Online</h5>
      <p>
        Temos opções de cursos que acontecem online, em tempo real com aulas pré agendadas em plataforma digital, ou uma
        opção de aulas gravadas e disponibilizadas na nossa plataforma de ensino para você assistir quantas vezes
        quiser.
      </p>
      <h5>Ensino Presencial</h5>
      <p>
        Este formato é o mais tradicional e mais conhecido por todos os nossos alunos aos longos dos anos. Neste formato
        os encontros acontecem presencialmente com um número pequeno de participantes para um melhor acompanhamento.
      </p>
    </div>
  )
}

export { InternalHowDoYouLearn }
