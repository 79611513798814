import React from 'react'
import PropTypes from 'prop-types'
import TopCard from '../../images/home/top_card.svg'
import styles from './InternalWorkshop.module.css'
function InternalWorkshopNoTab({ children }) {
  return (
    <div className={`${styles.internalWorkshop} ${styles.internalWorkshopNoTab}`}>
      <div className={styles.internalTabsNoTab}>
        <TopCard className={styles.topCard} />
        <div className={styles.internalNoTab}>{children}</div>
      </div>
    </div>
  )
}

InternalWorkshopNoTab.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
}

export { InternalWorkshopNoTab }
