import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { Image } from '../UI'
import cn from 'classnames'
import styles from './InternalHeader.module.css'
import Background from '../../images/home/banner_shape_background.svg'
import BackgroundMobile from '../../images/home/banner_shape_background_mobile.svg'

function InternalHeader({ children, withType, className }) {
  const sectionClassName = cn(styles.container, {
    [styles.containerwithType]: withType
  })

  const textBox = cn(styles.textBox, className)

  const { flowers } = useStaticQuery(
    graphql`
      query Internals {
        flowers: file(relativePath: { eq: "internals/petalas-rosa.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )
  return (
    <section className={sectionClassName}>
      <div className={styles.content}>
        <div className={textBox}>{children}</div>
      </div>
      <div className={styles.background}>
        <Image className={styles.flowers} fluid={flowers.childImageSharp.fluid} style={{ position: 'absolute' }} />
        <Background className={styles.shape} />
        <BackgroundMobile className={styles.shapeMobile} />
      </div>
    </section>
  )
}

InternalHeader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
  withType: PropTypes.bool
}

export { InternalHeader }
