import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import styles from './InternalContent.module.css'
function InternalContent({ children, className }) {
  const containerClass = cn(styles.internalContent, className)

  return <div className={containerClass}>{children}</div>
}

InternalContent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string
}

export { InternalContent }
