import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Image } from '../UI'
import TopCard from '../../images/home/top_card.svg'
import styles from './InternalPresentation.module.css'
import { WHATSAPPLINK } from '../../constants/routes'
function InternalPresentation() {
  const { owner } = useStaticQuery(
    graphql`
      query HomeContent {
        owner: file(relativePath: { eq: "home/owner.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )
  return (
    <div className={styles.internalPresentation}>
      <TopCard className={styles.topCard} />
      <small>Online ou Presencial</small>
      <h3>
        Atendimento
        <br />
        <strong>Terapêutico</strong>
      </h3>
      <Image className={styles.owner} fluid={owner.childImageSharp.fluid} />
      <h4>Luciana Moura</h4>
      <p>
        Terapeuta Floral Sistêmica
        <br />
        CRTH - BR 2285
        <br />
        Register of Practitioners BZP20121210R
        <br />
        Consteladora Familiar
      </p>
      <a
        href={WHATSAPPLINK}
        title='Quero Agendar'
        target='_blank'
        rel='noopener noreferrer'
        className={`button button--purpleReverse ${styles.button}`}
      >
        Quero Agendar <small className={styles.newWindow}></small>
      </a>
    </div>
  )
}

export { InternalPresentation }
